// @mui
import { Box, CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// hooks
import { useFile } from '~/swr/file'
// @mux
import MuxPlayer from '@mux/mux-player-react'
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

const CenterContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
})

const SIGNED_URL_REGEX = /https:\/\/stream\.mux\.com\/(.*)\.m3u8\?token=(.*)/
const getPlaybackParams = (signedURL) => {
  if (!signedURL) return null

  const match = SIGNED_URL_REGEX.exec(signedURL)

  if (!match) return null

  const [_, playbackId, playbackToken] = match

  return { playbackId, playbackToken }
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.fileId
 * @param {string} props.accessContext
 * @param {string} props.accessContext.contextType
 * @param {string} props.accessContext.contextId
 * @returns {JSX.Element}
 */
export default function VideoPlayer({
  fileId,
  accessContext,
  autoPlay = false
}) {
  const { _ } = useLingui()

  const { data, isLoading } = useFile(
    ...(fileId ? [fileId, accessContext] : [null])
  )
  const playbackParams =
    data?.status === 'ready' ? getPlaybackParams(data?.signedURL) : null
  const isVideoAvailable = Boolean(playbackParams)

  return (
    <Box sx={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black'
        }}
      >
        {isLoading ? (
          <CenterContent>
            <CircularProgress />
          </CenterContent>
        ) : !isVideoAvailable ? (
          <CenterContent>
            <Typography
              sx={{ textAlign: 'center', fontSize: 'small', color: 'white' }}
            >
              {data.status === 'ready'
                ? _(
                    msg`Tidak dapat memuat video, refresh halaman dan coba lagi...`
                  )
                : _(
                    msg`Video masih diproses, silakan tunggu beberapa saat dan coba lagi...`
                  )}
            </Typography>
          </CenterContent>
        ) : (
          <MuxPlayer
            playbackId={playbackParams.playbackId}
            tokens={{
              playback: playbackParams.playbackToken,
              thumbnail: playbackParams.playbackToken,
              poster: playbackParams.playbackToken
            }}
            style={{ aspectRatio: 16 / 9 }}
            autoPlay={autoPlay}
          />
        )}
      </Box>
    </Box>
  )
}
